import * as React from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Api from './Api';

const clone = obj => {
  return JSON.parse(JSON.stringify(obj));
}

const WidgetEditor_RefinanceCalculator_Products = ({ widgetProducts, changeHandler, config }) => {
  const [products, setProducts] = React.useState(widgetProducts);
  const [availableProducts, setAvailableProducts] = React.useState([]);
  const [productInEdit, setProductInEdit] = React.useState(null);
  const [isProductNew, setIsProductNew] = React.useState(false);
  const api = new Api();


  const moveUp = product => {
    const clonedProducts = clone(products);
    const i = clonedProducts.findIndex(p => { return p.code === product.code; });
    if (i < 1) return;

    const stage = clonedProducts[i - 1];
    clonedProducts[i - 1] = clonedProducts[i];
    clonedProducts[i] = stage;
    setProducts(clonedProducts);
    changeHandler('products', clonedProducts);
  }

  const moveDown = product => {
    const clonedProducts = clone(products);
    const i = clonedProducts.findIndex(p => { return p.code === product.code; });
    if (i >= clonedProducts.length - 1) return;

    const stage = clonedProducts[i + 1];
    clonedProducts[i + 1] = clonedProducts[i];
    clonedProducts[i] = stage;
    setProducts(clonedProducts);
    changeHandler('products', clonedProducts);
  }

  const addProduct = () => {
    api.getProducts(config.siteId, config.apiVersion, results => {
      setAvailableProducts(results);

      const newProductInEdit = {
        code: undefined,
        display: undefined,
        description: '',
        linkText: 'Get Quote',
        linkUrl: 'LeadCapture'
      };

      setIsProductNew(true);
      setProductInEdit(newProductInEdit);
    });
  }

  const getProductName = (productCode) => {
    const product = this.state.availableProducts.find(ap => ap.productCode == productCode);
    if (!product) {
      return productCode;
    }
    return product.productName;
  }

  const editProduct = product => {
    api.getProducts(config.siteId, config.apiVersion, results => {
      setAvailableProducts(results);
      setIsProductNew(false);
      setProductInEdit(product);
    });
  }

  const onProductChange = (field, value) => {
    const clonedProductInEdit = clone(productInEdit);
    const numericFields = [
      'loanTerm',
      'fixedPeriod',
      'adjustmentPeriod',
      'margin',
      'periodicCap',
      'lifetimeCap',
    ]
    if (numericFields.includes(field)) value = Number(value);
    clonedProductInEdit[field] = value;
    setProductInEdit(clonedProductInEdit);
  }

  const completeProductEdit = () => {
    const clonedProducts = clone(products);
    const i = clonedProducts.findIndex(p => { return p.code === productInEdit.code; });

    if (i === -1) {
      clonedProducts.push(productInEdit);
    } else {
      clonedProducts.splice(i, 1, productInEdit);
    }
    setProducts(clonedProducts);
    setProductInEdit(null);
    changeHandler('products', clonedProducts);
  }

  const removeProduct = product => {
    if (!window.confirm("Are you sure you want to remove this product?")) {
      return;
    }
    const clonedProducts = clone(products);
    const i = clonedProducts.findIndex(p => { return p.code === product.code; });
    clonedProducts.splice(i, 1);
    setProducts(clonedProducts);
    setProductInEdit(null);
    changeHandler('products', clonedProducts);
  }

  return (
    <React.Fragment>
      {!productInEdit &&
        <Box>
          <TableContainer component={Paper} style={{ marginBottom: "30px" }}>
            <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ background: "rgb(235 235 235)" }}>
                  <TableCell>Product</TableCell>
                  <TableCell align="left">Display Name</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product, index) => (
                  <TableRow
                    key={product.code}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="product" title={product.code}>
                      {this.getProductName(product.code)}
                    </TableCell>
                    <TableCell align="left">{product.display}</TableCell>
                    <TableCell align="right">
                      {index > 0 &&
                        <Button
                          variant="contained"
                          startIcon={<ArrowUpward />}
                          class="mbtn mbtn-arrow-up"
                          onClick={() => moveUp(product)}
                        ></Button>
                      }
                      {index < products.length - 1 &&
                        <Button
                          variant="contained"
                          startIcon={<ArrowDownward />}
                          class="mbtn mbtn-arrow-down"
                          onClick={() => moveDown(product)}
                        >
                        </Button>
                      }
                      <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        class="mbtn mbtn-edit"
                        onClick={() => editProduct(product)}
                      >
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={3}>
                    <Link onClick={() => addProduct()}>
                      <AddIcon></AddIcon> <div>Add Product</div>
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
      {productInEdit &&
        <React.Fragment>
          <div className="curtain"></div>
          <Paper className="product-edit paper" variant="elevation">
            <Grid container spacing={2}>
              <Grid item xs={6} align="left">

                <Box className="Input" fullWidth>
                  <FormControl fullWidth>
                    <InputLabel className="selectLabelCorrection">Product</InputLabel>
                    <Select
                      value={productInEdit.code}
                      label="Product"
                      disabled={!isProductNew}
                      onChange={(event) => onProductChange("code", event.target.value)}
                      variant="outlined"
                    >
                      {availableProducts.map(p => (
                        <MenuItem value={p.productCode}>{p.productName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box className="Input" fullWidth>
                  <FormControl fullWidth>
                    <InputLabel className="selectLabelCorrection">Rate Type</InputLabel>
                    <Select
                      value={productInEdit.rateType}
                      label="Rate Type"
                      onChange={(event) => onProductChange("rateType", event.target.value)}
                      variant="outlined"
                    >
                      <MenuItem value="fixed">Fixed</MenuItem>
                      <MenuItem value="arm">Adjustable-Rate (ARM)</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box className="Input" fullWidth>
                  <TextField
                    fullWidth
                    label="Loan Term In Months"
                    type="number"
                    value={productInEdit.loanTerm}
                    onChange={(event) => onProductChange("loanTerm", event.target.value)}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="Input" fullWidth>
                  <TextField
                    label="Display"
                    fullWidth
                    value={productInEdit.display}
                    onChange={(event) => onProductChange("display", event.target.value)}
                    variant="outlined"
                  />
                </Box>
                <Box className="Input" fullWidth>
                  <TextField
                    fullWidth
                    label="Link Text"
                    value={productInEdit.linkText}
                    onChange={(event) => onProductChange("linkText", event.target.value)}
                    variant="outlined"
                  />
                </Box>
                <Box className="Input" fullWidth>
                  <TextField
                    fullWidth
                    label="Link URL"
                    value={productInEdit.linkUrl}
                    onChange={(event) => onProductChange("linkUrl", event.target.value)}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              {productInEdit.rateType === 'arm' &&
                <>
                  <Grid item xs={6}>
                    <Box className="Input" fullWidth>
                      <TextField
                        label="ARM Fixed Period In Months"
                        type="number"
                        fullWidth
                        value={productInEdit.fixedPeriod}
                        onChange={(event) => onProductChange("fixedPeriod", event.target.value)}
                        variant="outlined"
                      />
                    </Box>
                    <Box className="Input" fullWidth>
                      <TextField
                        fullWidth
                        label="ARM Adjustment Period In Months"
                        type="number"
                        value={productInEdit.adjustmentPeriod}
                        onChange={(event) => onProductChange("adjustmentPeriod", event.target.value)}
                        variant="outlined"
                      />
                    </Box>
                    <Box className="Input" fullWidth>
                      <TextField
                        fullWidth
                        label="ARM Margin"
                        type="number"
                        value={productInEdit.margin}
                        onChange={(event) => onProductChange("margin", event.target.value)}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="Input" fullWidth>
                      <TextField
                        label="ARM Periodic Cap"
                        type="number"
                        fullWidth
                        value={productInEdit.periodicCap}
                        onChange={(event) => onProductChange("periodicCap", event.target.value)}
                        variant="outlined"
                      />
                    </Box>
                    <Box className="Input" fullWidth>
                      <TextField
                        fullWidth
                        label="ARM Lifetime Cap"
                        type="number"
                        value={productInEdit.lifetimeCap}
                        onChange={(event) => onProductChange("lifetimeCap", event.target.value)}
                        variant="outlined"
                      />
                    </Box>
                    <Box className="Input" fullWidth>
                      <FormControl fullWidth>
                        <InputLabel className="selectLabelCorrection">CMT Rate To Use For Index Rate</InputLabel>
                        <Select
                          value={productInEdit.cmtIndexKey}
                          label="CMT Rate To Use For Index Rate"
                          onChange={(event) => onProductChange("cmtIndexKey", event.target.value)}
                          variant="outlined"
                        >
                          <MenuItem value="1_year_cmt">1 Year CMT</MenuItem>
                          <MenuItem value="5_year_cmt">5 Year CMT</MenuItem>
                          <MenuItem value="7_year_cmt">7 Year CMT</MenuItem>
                          <MenuItem value="10_year_cmt">10 Year CMT</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </>
              }
            </Grid>
            <Grid item xs={12}>
              <h5><strong>*</strong>Link URL can contain (1) a website address (link) to another page, (2) a javascript command e.g 'javascript::', or (3) type 'LeadCapture' to call up the Lead Capture dialog</h5>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} align="left">
                {!isProductNew &&
                  <Button onClick={() => removeProduct(productInEdit)} variant="contained" color="secondary">REMOVE</Button>
                }
              </Grid>
              <Grid item xs={6} align="right">
                <Button onClick={() => setProductInEdit(null)}>Cancel</Button>
                {isProductNew &&
                  <Button onClick={() => completeProductEdit()} variant="contained" color="primary">ADD PRODUCT</Button>
                }
                {!isProductNew &&
                  <Button onClick={() => completeProductEdit()} variant="contained" color="primary">SAVE PRODUCT</Button>
                }

              </Grid>
            </Grid>
          </Paper>
        </React.Fragment>
      }

    </React.Fragment>
  )
};

export default WidgetEditor_RefinanceCalculator_Products;