import logo from './logo.svg';
import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import './App.css';
import CreditUnions from './Components/CreditUnions';
import CreditUnion from './Components/CreditUnion';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Api from './Components/Api';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit'
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

export default class App extends React.Component {

    state = {
        selectedTab: 0,
        apiVersion: 0,
        allowCuSelection: true,
        siteId: null,
        passCode: '',
        authenticated: false,
        authenticating: true,
        mamConfigs: [],
        recycleStatus: "",
        recyclePending: false,
        productBrokenProductReferences: null
    };

    constructor(props) {
        super(props);
        console.log("rendering widget admin 1.0");
        if (window.cuId) {
            this.state.allowCuSelection = false;
            this.state.siteId = window.cuId;
        }
    }

    componentDidMount() {
      this.authenticate();
      console.log("Gateway API:", process.env.REACT_APP_MAM_GATEWAY_API_URL);
    }

    handleSelectCu = (id) => {
      if (this.state.allowCuSelection) {
        this.setState({ siteId: id });
      }
    }

    deleteCu = (siteId) => {
      if (!window.confirm("Are you sure you want to delete this credit union?")) {
        return;
      }

      var api = new Api();
      api.deleteConfig(siteId, (result, err) => {
        if (err && err?.response?.status === 401) {
          alert("Credit Union not deleted. Your session has expired. Please login and try again.");
          this.logout();
          return;
        }
        this.handleSelectCu('');
      });
    }

    authenticate = () => {
      var token = localStorage.getItem('mam-widget-admin-token');
      if (!token) {
        this.setState({authenticating: false });
        return;
      }
      var api = new Api();
      api.authenticate(token, result => {
        console.log("AuthenticateResult", result);
        this.setState({authenticating: false});
        if (result && result.data) {
          console.log("Authenticated!");
          this.setState({authenticated: true});
        } else {
          console.log("Not authenticated. Login required.");
          this.logout();
        }
      });

    }

    login = () => {
      var api = new Api();
      api.login(this.state.passCode, result => {
        console.log("LoginResult", result);
        if (result.data) {
          localStorage.setItem('mam-widget-admin-token', result.data);
          this.setState({authenticated: true });
        } else {
          alert("Invalid password");
        }
      });
    }

    logout = () => {
      localStorage.removeItem('mam-widget-admin-token');
      this.setState({ authenticated: false });
    }

    selectTab = (e, i) => {
      
      if (i == 0) {
        this.setState({ selectedTab: i });
        return;
      }
      
      this.setState({ selectedTab: i });

      if (i == 1) {
        var apiVersion = 99;
        this.setState({ apiVersion });
      } else if ( i > 1) {
        var apiVersion = i + 2;
        this.setState({ apiVersion });
      }

      var api = new Api();
      api.getMamConfig(apiVersion, result => {
        console.log(result);
        this.setState({mamConfigs: result});
      })


    }

    onMamConfigChange = (id, value) => {
      var mamConfigs = this.state.mamConfigs;
      for (var i = 0; i < mamConfigs.length; i++) {
        if (mamConfigs[i].id == id) {
          mamConfigs[i].value = value;
        }
      }
      this.setState({mamConfigs});
    }

    logout = () => {
      localStorage.removeItem('mam-widget-admin-token');
      window.location.reload();
    }

    saveConfig= () => {
      if (!window.confirm("This configuration applies to all Credit Union sites. Are you sure you want to saved these settings?")) {
        return;
      }

      var api = new Api();
        api.saveMamConfig({ apiVersion: this.state.apiVersion, configs: this.state.mamConfigs}, (result, err) => {
          if (err && err?.response?.status === 401) {
            alert("Config not saved. Your session has expired. Please login and try again.");
            this.logout();
            return;
          }
          alert("Saved successfully");
        });
    }

    recycleAll = () => {

      this.setState({ recycleStatus: true, recyclePending: true });

      // api v4 - refresh rates
      // gateway - clear cache
      // 
      const steps = [
        { url: process.env.REACT_APP_MAM_GATEWAY_API_URL + 'rates/refresh', message: "Refreshing Rates for all versioned API's ..." },
        { url: process.env.REACT_APP_MAM_GATEWAY_API_URL + 'clearCache', message: 'Clearing Gateway Cache ...' }
      ];

      
      const pingNext = () => {
        
        var i = 0;
        for (i = 0; i < steps.length; i++) {
          if (!steps[i].completed)
            break;
        }

        if (i != steps.length) {
          
          var step = steps[i];
          this.setState({ recycleStatus: step.message });
          setTimeout(() => {
            axios.get(step.url, new Api().axiosOptions).then(() => {
              step.completed = true;
              pingNext();
            }).catch((e) => {
              if (e?.response?.status === 401) {
                alert("Rates not refreshed. Your session has expired. Please login and try again.");
                this.logout();
                return;
              }
              console.log("Recycle error", e);
              this.setState({ recycleStatus: null });
              alert("Issued ocurred recycling! See console for details.");
            });
          }, 2000);

          return;
        }

        //[ Completed! ]
        this.setState({ recycleStatus: "Refresh Complete!", recyclePending: false });
        setTimeout(() => {
          this.setState({ recycleStatus: null });
        }, 5000)
      }

      pingNext();

    }

    orphanedProductReferenceCheck() {
      var that = this;
      var id = 0;
      var errors = [];
      var api = new Api();
      api.getCreditUnions(function(creditUnions) {
        for (var i = 0; i < creditUnions.length; i++) {
          api.getCreditUnion(creditUnions[i].siteId, function(creditUnion) {
            api.getProducts(creditUnion.siteId, creditUnion.apiVersion, function(availableProducts) {
              for (var w = 0; w < creditUnion.widgets.length; w++) {
                var widget = creditUnion.widgets[w];
                if (widget.products) {
                  for (var p = 0; p < widget.products.length; p++) {
                    var product = widget.products[p];
                    if (availableProducts.indexOf(product.code) == -1) {
                      errors.push( { id: id, cuName: creditUnion.name, widgetId: widget.id, productCode: product.code });
                      id++;
                      that.setState({productBrokenProductReferences : errors});
                    }
                  }
                }
              }
            })
          })
        }
      })

    }

    render() {

        return (
          <Container className="mam-admin-wrapper">
            { this.state.authenticated &&
                <div className="Wrapper">
                  <div id="Toast">
                    <div id="ToastContent">
                    </div>
                  </div>

                  <div className="App">

                    { !this.state.siteId &&
                      <div style={{marginTop:"40px", display: 'flex',flexGrow: '3'}}>
                        <div style={{ display: 'block' }}>
                          <div style={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={this.state.selectedTab} onChange={this.selectTab} aria-label="basic tabs example">
                              <Tab label="Credit Unions" />
                              <Tab label="API UAT Settings" />
                              <Tab label="API V4 Settings" />
                              <Tab label="API V5 Settings" />
                            </Tabs>
                          </div>
                          { this.state.productBrokenProductReferences &&
                            <div>
                              { this.state.productBrokenProductReferences.length > 0 &&
                                <div >
                                  <h4 className="text-danger">Product Reference Check Results | The following products have broken references:</h4>
                                  { this.state.productBrokenProductReferences.map(error => (
                                    <div key={error.id}>
                                      Credit Union: {error.cuName}, Widget: {error.widgetId}, Product: {error.productCode}
                                      <br />
                                    </div>
                                  ))}
                                  <br />
                                </div>
                              }
                                
                              { !this.state.productBrokenProductReferences.length &&
                                <h4 className="text-success">
                                  Scanned all products for all Credit Unions and no broken references were found.
                                </h4>
                              }
                            </div>
                          }
                        
                          { this.state.selectedTab == 0 &&
                            <div value={this.state.selectedTab} index={0}>
                              <CreditUnions
                                  handleSelectCu={this.handleSelectCu}
                                  logout = {this.logout}
                              />
                            </div>
                          }
                          { this.state.selectedTab > 0 &&
                            <div className="mamConfigTab" value={this.state.selectedTab} index={1}>
                              { this.state.mamConfigs.map(config => (
                                <Grid container spacing={2} key={config.id} style={{marginTop:30,borderBottom:'solid 1px gray'}}>
                                  <Grid item md={8}>
                                    <div >
                                      <Box className="Input">
                                        <TextField
                                          label={config.name}
                                          multiline
                                          minRows={6}
                                          maxRows={20}
                                          style={{width: "100%" }}
                                          value={config.value}
                                          onChange={(event) => this.onMamConfigChange(config.id, event.target.value)}
                                          variant="outlined"
                                          disabled={config.readOnly}
                                        />
                                      </Box>
                                    </div>
                                  </Grid>
                                  <Grid item md={4}>
                                    { config.notes && 
                                        <div style={{fontSize:12, wordWrap: 'break-word'}} dangerouslySetInnerHTML={{__html: config.notes}}></div>  
                                    }
                                  </Grid>
                                </Grid>
                              ))}

                              <div class="clear:both"></div>
                                                                
                              <Button
                                onClick={() => this.saveConfig()}
                                variant="contained"
                                color="primary">Save And Apply
                              </Button>
                            </div>
                          }
                        </div>
                        <div style={{borderLeft: 'solid 1px black', flexGrow: '1', paddingLeft:'10px', marginLeft: '10px'}}>
                            <div style={{textAlign: 'left'}}>
                              <div style={{height: '80px'}}></div>
                            {/* { !this.state.productBrokenProductReferences &&
                                <div style={{marginRight: "5px", marginBottom: '10px'}}>
                                  <Button onClick={() => this.orphanedProductReferenceCheck()} variant="contained" color="primary">Check Product References</Button>
                                </div>
                            } */}

                            <div style={{marginRight: "5px", marginBottom: '10px'}}>
                              { !this.state.recycleStatus &&
                                  <Button onClick={() => this.recycleAll()} variant="contained" color="primary">Refresh Rates (All Versions)</Button>
                              }
                              { this.state.recycleStatus &&
                                <div>
                                  <Button variant="contained" color="primary">
                                    <Box style={{marginLeft: "0px"}}>
                                      { this.state.recyclePending && <CircularProgress size="10px" color="white" /> }
                                      { !this.state.recyclePending && <CheckCircle fontSize='small' /> }
                                    </Box>
                                    <div style={{marginLeft: "10px"}}>
                                    Refreshing
                                    </div>
                                      
                                  </Button>
                                </div>
                              }
                            </div>
                            <div style={{ marginRight: "5px", marginBottom: '10px'}}>
                              <Button onClick={() => window.open(process.env.REACT_APP_MAM_API_V5_URL + "rates/scenarios", "_blank")} variant="contained" color="primary">View Rate Scenarios (V5)</Button>
                            </div>
                            <div style={{ marginRight: "5px", marginBottom: '10px'}}>
                              <Button onClick={() => window.open(process.env.REACT_APP_MAM_API_V5_URL + "rates/latest-rates-data", "_blank")} variant="contained" color="primary">Latest Rates Fetch Results (V5)</Button>
                            </div>
                            <div style={{ marginRight: "5px", marginBottom: '10px'}}>
                              <Button onClick={() => window.open(process.env.REACT_APP_MAM_API_V5_URL + "rates/assumption-tokens", "_blank")} variant="contained" color="primary">Assumptions Template Tokens (V5)</Button>
                            </div>
                            <div style={{ marginRight: "5px", marginBottom: '10px'}}>
                              <Button onClick={() => window.open(process.env.REACT_APP_MAM_API_V5_URL + "rates/orphaned-product-references", "_blank")} variant="contained" color="primary">Orphaned Product References (V5)</Button>
                            </div>
                            <div style={{ marginRight: "5px", marginBottom: '10px'}}>
                              <Button style={{width: '100%'}} onClick={() => this.logout()} variant="contained" color="secondary">Logout</Button>
                            </div>
                          </div>

                        </div>
                      </div>
                    }
                    {
                        this.state.siteId &&
                        <CreditUnion
                            allowCuSelection={this.state.allowCuSelection}
                            handleSelectCu={this.handleSelectCu}
                            deleteCu={this.deleteCu}
                            siteId={this.state.siteId}
                            logout={this.logout}
                        />
                    }
                  </div>
              </div>
            }
            { !this.state.authenticated && !this.state.authenticating && 
              <div style={{textAlign:"center", paddingTop: "30px"}}>
                <h4>MAM Widget Admin</h4>
                <input type="password" name="passcode" placeholder="Enter Passcode" onChange={(e) => this.setState({passCode: e.target.value})} />
                <button onClick={() => this.login()}>Login</button>
              </div>
            }
            { this.state.authenticating && 
              <h4>Authenticating...</h4>
            }
            
          </Container>
        );
    }
}