import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

export default function WidgetEditor_PaymentCalculator_Config(props) {

  return (
    <React.Fragment>
      <Grid container spacing={2}>

        <Grid item xs={4}>
          <Box className="Input">
            <FormControl fullWidth>
              <TextField
                  label="Title"
                  multiline
                  maxRows={3}
                  style={{width: "100%"}}
                  value={props.widget.title}
                  onChange={(event) => props.onChange("title", event.target.value)}
                  variant="outlined"
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="Input">
            <TextField
                label="Link Text"
                multiline
                maxRows={3}
                style={{width: "100%"}}
                value={props.widget.linkText}
                onChange={(event) => props.onChange("linkText", event.target.value)}
                variant="outlined"
              />
          </Box>
        </Grid>
        <Grid item xs={4}>
           <Box className="Input">
             <TextField
                 label="Link Url *"
                 multiline
                 maxRows={3}
                 style={{width: "100%"}}
                 value={props.widget.linkUrl}
                 onChange={(event) => props.onChange("linkUrl", event.target.value)}
                 variant="outlined"
               />
           </Box>
         </Grid>

        <Grid item xs={6}>
          <Box className="Input">
            <FormControl fullWidth>
              <TextField
                  label="Veteran Question Text"
                  multiline
                  maxRows={3}
                  style={{width: "100%"}}
                  value={props.widget.veteranQuestionText}
                  onChange={(event) => props.onChange("veteranQuestionText", event.target.value)}
                  variant="outlined"
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="Input">
            <TextField
                label="Memo"
                multiline
                maxRows={3}
                style={{width: "100%"}}
                value={props.widget.memo}
                onChange={(event) => props.onChange("memo", event.target.value)}
                variant="outlined"
              />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box className="Input">
            <TextField
                label="Assumptions Text"
                multiline
                minRows={5}
                style={{width: "100%"}}
                value={props.widget.assumptionsText}
                onChange={(event) => props.onChange("assumptionsText", event.target.value)}
                variant="outlined"
              />
          </Box>
        </Grid>
        <Grid xs={3}>
          <h5 style={{marginTop:0, marginBottom:0}}>Tokens (to be replaced by values provided by user)</h5>
          <ul>
            <li>[DownPayment]</li>
            <li>[PurchasePrice]</li>
            <li>[LoanAmount]</li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <h5><strong>*</strong>Link URL can contain (1) a website address (link) to another page, (2) a javascript command e.g 'javascript::', or (3) type 'LeadCapture' to call up the Lead Capture dialog</h5>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
