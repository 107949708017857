import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

export default function WidgetEditor_MicroWidget_Config(props) {

  return (
    <React.Fragment>
      <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box className="Input">
              <FormControl fullWidth>
                <InputLabel className="selectLabelCorrection">Display</InputLabel>
                <Select
                  value={props.widget.property}
                  label="Display"
                  onChange={(event) => props.onChange("property", event.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="rate">Rate</MenuItem>
                  <MenuItem value="apr">APR</MenuItem>
                  <MenuItem value="payment">Payment</MenuItem>
                  <MenuItem value="points">Points</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="Input">
              <FormControl fullWidth>
                <InputLabel className="selectLabelCorrection">APR Precision</InputLabel>
                <Select
                  label="Precision"
                  value={props.widget.precision}
                  onChange={(event) => props.onChange("precision", event.target.value)}
                  variant="outlined"
                >
                  <MenuItem value={0}>No decimal places</MenuItem>
                  <MenuItem value={1}>1 decimal place</MenuItem>
                  <MenuItem value={2}>2 decimal places</MenuItem>
                  <MenuItem value={3}>3 decimal places</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="Input">
              <TextField
                  label="Memo"
                  multiline
                  maxRows={3}
                  style={{width: "100%"}}
                  value={props.widget.memo}
                  onChange={(event) => props.onChange("memo", event.target.value)}
                  variant="outlined"
                />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="Input">
                <FormControl fullWidth>
                  <InputLabel className="selectLabelCorrection">Product</InputLabel>
                  <Select
                    label="Product"
                    value={props.widget.product}
                    onChange={(event) => props.onChange("product", event.target.value)}
                    variant="outlined"
                  >
                    { props.products.map(p => (
                      <MenuItem value={p.productCode}>{p.productName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
          </Grid>
        </Grid>
    </React.Fragment>
    );
}
