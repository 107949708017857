import * as React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CreditUnionConfigTable from './CreditUnionConfigTable';
import CreditUnionWidgetsTable from './CreditUnionWidgetsTable';
import WidgetEditorModal from './WidgetEditorModal'
import ConfigurationModal from './ConfigurationModal'
import Api from './Api';

export default class CreditUnion extends React.Component {

    state = {
        showAddWidget: false,
        widgetInEdit: null,
        widgetToAdd: null,
        configInEdit: null,
    };

    constructor(props) {
        super(props);
        this.state.siteId = props.siteId;
    }

    componentDidMount() {
        this.loadCreditUnion();
    }

    loadCreditUnion() {

        this.api = new Api();

        if (this.state.siteId != 'NEW') {
            this.api.getCreditUnion(this.state.siteId, result => {
                console.log("Credit Union Config:", result);
                this.setState({ config: result });
            });
        } else {
            var newConfig = this.getDefaultConfig();
            this.setState({ config: newConfig }, () => this.editConfig())
        }
    }

    closeAddWidget = () => {
        this.setState({ showAddWidget: false });
    }
    openAddWidget = () => {
        this.setState({ showAddWidget: true });
    }

    selectWidgetToAdd = (widgetToAdd) => {
        this.setState({ widgetToAdd });
    }



    addWidget = () => {

        var widgetConfig = null;
        switch (this.state.widgetToAdd) {
            case "PaymentCalculator":
                widgetConfig = this.getDefaultWidgetConfig_PaymentCalculator();
                break;
            case "TodaysRatesCards":
                widgetConfig = this.getDefaultWidgetConfig_TodaysRatesCards();
                break;
            case "TodaysRatesTable":
                widgetConfig = this.getDefaultWidgetConfig_TodaysRatesTable();
                break;
            case "MicroWidget":
                widgetConfig = this.getDefaultWidgetConfig_MicroWidget();
                break;
            case "RentVsBuyCalculator":
                widgetConfig = this.getDefaultWidgetConfig_RentVsBuyCalculator();
                break;
            case "HowMuchDoINeedCalculator":
                widgetConfig = this.getDefaultWidgetConfig_HowMuchDoINeedCalculator();
                break;
            case "HowMuchCanIAffordCalculator":
                widgetConfig = this.getDefaultWidgetConfig_HowMuchCanIAffordCalculator();
                break;
            case "RefinanceCalculator":
                widgetConfig = this.getDefaultWidgetConfig_RefinanceCalculator();
                break;
            default:
                alert("Must select a widget to add");
                break;
        }

        this.setState({ showAddWidget: null });
        this.editWidget(widgetConfig);
    }

    editWidget = (widget) => {
        this.setState({ widgetInEdit: widget });
    }

    editConfig = () => {
        this.setState({ configInEdit: JSON.parse(JSON.stringify(this.state.config)) })
    }

    deleteWidget = (widget) => {
        if (!window.confirm("Are you sure you want to permanently delete this widget?")) {
            return;
        }

        var config = JSON.parse(JSON.stringify(this.state.config));

        var i = config.widgets.findIndex(w => { return w.id == widget.id });
        config.widgets.splice(i, 1);

        this.saveConfig(config, result => {
            if (result.success) {
            }
        });
    }

    editWidgetComplete = (widget) => {


        // cancelled out of dialog
        if (!widget) {
            this.setState({ widgetInEdit: null });
            return;
        }

        var config = JSON.parse(JSON.stringify(this.state.config));

        // add or update widget on config
        var i = config.widgets.findIndex(w => { return w.id == widget.id; })

        if (i > -1) {
            config.widgets[i] = widget;
        } else {
            config.widgets.push(widget);
        }

        // try to save to server
        this.saveConfig(config, result => {
            if (result.success) {
                this.setState({ widgetInEdit: null });
            }
        });

    }

    editConfigComplete = (config) => {

        // cancel out of dialog
        if (!config) {
            this.setState({ configInEdit: null });
            if (this.state.siteId == 'NEW') {
                this.props.handleSelectCu('');
            }
            return;
        }
        // try to save to server
        this.saveConfig(config, result => {
            if (result.success) {
                this.setState({ configInEdit: null });
            }
        });
    }

    saveConfig(config, callback) {

        this.api.saveConfig(config, (result, err) => {
            if (err && err.response && err.response.status === 401) {
                alert("Config not saved. Your session has expired. Please login and try again.");
                this.props.logout();
                return;
            }
            if (result.success) {
                this.toast("Saved successfully", 'success');
                this.setState({ siteId: config.siteId }, () => this.loadCreditUnion());
            } else {
                alert(result.errors[0]);
            }
            callback(result);
        })
    }

    getDefaultConfig() {
        return {
            apiVersion: 0,
            lockPeriod: 90,
            widgets: []
        };
    }

    getDefaultWidgetConfig_PaymentCalculator = () => {
        return {
            id: "NEW",
            type: "PaymentCalculator",
            linkText: "Get Quote",
            linkUrl: "LeadCapture",
            veteranQuestionText: "Are you Military or a Veteran?",
            title: "Payment Calculator",
            products: [],
            assumptionsText: ''
        };
    }
    getDefaultWidgetConfig_TodaysRatesCards = () => {
        return {
            id: "NEW",
            type: "TodaysRatesCards",
            ratePrecision: 2,
            aprPrecision: 3,
            title: 'Today\'s Mortgage Rates',
            caption: 'This table includes the most common loan programs along with today\'s "par" (i.e. no discount points) rate for each and are based on certain assumptions. Your specific rate will be based on your unique circumstances and may differ from the rates shown in this table. Be sure to ask about additional loan programs and rates when you speak with a Mortgage Loan Originator. Click the "Get Quote" button to obtain a personalized rate quote.',
            products: []
        };
    }
    getDefaultWidgetConfig_TodaysRatesTable = () => {
        return {
            id: "NEW",
            type: "TodaysRatesTable",
            ratePrecision: 2,
            aprPrecision: 3,
            paymentDisplay: 'perLoanAmount',
            products: []
        };
    }

    getDefaultWidgetConfig_MicroWidget = () => {
        return {
            id: "NEW",
            type: "MicroWidget",
            property: 'apr',
            precision: 3,
            product: ''
        };
    }
    getDefaultWidgetConfig_RentVsBuyCalculator = () => {
        return {
            id: "NEW",
            type: "RentVsBuyCalculator",
            product: '30 Year Fixed',
            productType: 'conventional',
            loanTerm: 360,
            purchasePrice: 250000,
            downPaymentAmount: 50000,
            taxBracket: .15,
            inflationRate: .035,
            annualAppreciationRate: .02,
            annualHomeownersInsurancePremium: .0025,
            annualPropertyTaxes: .0175,
            realtorFeesOnFutureSale: .06,
            annualMaintenanceCost: .0025,
            closingCostsFixed: 3000,
            closingCostsVariablePercentage: .00125,
            closingCostsVariableThreshold: 150000,
            monthlyRentPayment: 1800,
            monthlyRentInsurance: 100,
            annualRentIncrease: .02,
            preTaxReturnOnSavings: .04,
            leadCaptureText: 'Get Quote',
            applyNowLinkUrl: '',
            memo: ''
        };
    }
    getDefaultWidgetConfig_RefinanceCalculator = () => {
        return {
            id: "NEW",
            type: "RefiCalculator",
            note: "",
            products: []
        };
    }
    getDefaultWidgetConfig_HowMuchDoINeedCalculator = () => {
        return {
            id: "NEW",
            type: "HowMuchDoINeedCalculator",
            purchasePrice: 500000,
            downPayment: 25000,
            monthlyAssociationDues: 0,
            monthlyCarPayments: 0,
            monthlyCreditCardPayments: 0,
            otherMonthlyObligations: 0,
            estimatedTaxesAndInsuranceSharePerc: .20,
            conservativeFrontEndRatio: .28,
            aggressiveFrontEndRatio: .36,
            conservativeBackEndRatio: .36,
            aggressiveBackEndRatio: .45,
            closingCostsFixed: 3000,
            closingCostsVariablePercentage: .00125,
            closingCostsVariableThreshold: 150000,
            applyNowLinkUrl: '',
            products: []
        };
    }
    getDefaultWidgetConfig_HowMuchCanIAffordCalculator = () => {
        return {
            id: "NEW",
            type: "HowMuchCanIAffordCalculator",
            annualIncome: 125000,
            downPayment: 25000,
            monthlyAssociationDues: 0,
            monthlyCarPayments: 0,
            monthlyCreditCardPayments: 0,
            otherMonthlyObligations: 0,
            estimatedTaxesAndInsuranceSharePerc: .20,
            conservativeFrontEndRatio: .28,
            aggressiveFrontEndRatio: .36,
            conservativeBackEndRatio: .36,
            aggressiveBackEndRatio: .45,
            closingCostsFixed: 3000,
            closingCostsVariablePercentage: .00125,
            closingCostsVariableThreshold: 150000,
            applyNowLinkUrl: '',
            products: []
        };
    }

    toast(message, type) {
        if (window.jQuery) {

            window.jQuery("#Toast")
                .hide()
                .removeClass('toast-danger')
                .removeClass('toast-warning')
                .removeClass('toast-success')
                .addClass('toast-' + type)
                ;

            window.jQuery("#ToastContent").html(message);
            window.jQuery("#Toast").show();

            setTimeout(function () {
                window.jQuery("#Toast").fadeOut();
            }, 3000)
        } else {
            alert(message);
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.config != null &&
                    <div className="CreditUnion">
                        <div className="mam-admin-header">
                            <div style={{ float: "left" }}>
                                <img className="logo" src={this.state.config.logo}></img>
                            </div>
                            <div style={{ float: "right", textAlign: "right" }}>
                                <Typography variant="h5" component="div" style={{ paddingTop: 25 }}>
                                    <strong>{this.state.config.name}</strong>
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    <strong>Widget Administration</strong>
                                </Typography>
                                {this.props.allowCuSelection &&
                                    <React.Fragment>
                                        <Link size="small" onClick={() => this.props.handleSelectCu('')}>(Change Credit Union)</Link>
                                    </React.Fragment>
                                }
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                        <div>
                            <Grid container spacing={8}>
                                <Grid item sm={12}>
                                    <CreditUnionConfigTable
                                        config={this.state.config}
                                        editConfig={this.editConfig}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item sm={12}>
                                    <CreditUnionWidgetsTable
                                        config={this.state.config}
                                        widgets={this.state.config.widgets}
                                        editWidget={this.editWidget}
                                        deleteWidget={this.deleteWidget}
                                        closeAddWidget={this.closeAddWidget}
                                        openAddWidget={this.openAddWidget}
                                        showAddWidget={this.state.showAddWidget}
                                        selectWidgetToAdd={this.selectWidgetToAdd}
                                        addWidget={this.addWidget}
                                    />
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                }
                {this.state.widgetInEdit &&
                    <WidgetEditorModal
                        widget={this.state.widgetInEdit}
                        config={this.state.config}
                        onClose={this.editWidgetComplete}
                    />
                }
                {this.state.configInEdit &&
                    <ConfigurationModal
                        config={this.state.configInEdit}
                        onClose={this.editConfigComplete}
                    />
                }

                {this.props.allowCuSelection &&
                    <React.Fragment>
                        <div style={{ marginTop: "15px", textAlign: "left" }}>
                            <Link size="small" style={{ color: "red", marginTop: "10px" }} onClick={() => this.props.deleteCu(this.state.siteId)}>Remove this Credit Union</Link>
                        </div>
                    </React.Fragment>
                }

            </React.Fragment>
        );
    }
}
